import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, signOut, onAuthStateChanged, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, addDoc, collection, serverTimestamp, onSnapshot, orderBy, query } from 'firebase/firestore';
import { auth, provider, db } from './firebaseConfig'; // Your Firebase config

const MAX_DIALOGUE_CHARACTERS = 6500;

function App() {
  const [dialogue, setDialogue] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [mbtiType, setMbtiType] = useState('');
  const [creditBalance, setCreditBalance] = useState(0);
  const [userDialogues, setUserDialogues] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [enneagramType, setEnneagramType] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [dialogueCount, setDialogueCount] = useState(5);
  const [usePremiumService, setUsePremiumService] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User logged in:", user);
        setLoggedInUser(user);
        setCurrentUser(user);

        const userDocRef = doc(db, `users/${user.uid}`);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          setMbtiType(docSnap.data().mbtiType || '');
          setCreditBalance(docSnap.data().creditBalance || 0);
          setIsPremium(docSnap.data().userType === 'premium' || false);
          setEnneagramType(docSnap.data().enneagramType || null);
          setDialogueCount(docSnap.data().dialogueCount !== undefined ? docSnap.data().dialogueCount : 5);
        } else {
          console.log(`No data available for user: ${user.uid}. Creating a new user document.`);
          await setDoc(userDocRef, {
            dialogueCount: 5,
            userType: 'standard',
          });
          setDialogueCount(5);
        }

        const userDialoguesRef = collection(db, `users/${user.uid}/dialogues`);
        onSnapshot(query(userDialoguesRef, orderBy('timestamp', 'desc')), (querySnapshot) => {
          const dialogues = [];
          querySnapshot.forEach((doc) => {
            dialogues.push(doc.data());
          });
          setUserDialogues(dialogues);
        });

        onSnapshot(doc(db, 'users', user.uid), (doc) => {
          setUserDetails(doc.data());
        });
      } else {
        console.log("No user logged in.");
        setLoggedInUser(null);
        setMbtiType('');
        setCreditBalance(0);
        setIsPremium(false);
        setEnneagramType(null);
        setCurrentUser(null);
        setUserDetails(null);
        setDialogueCount(0);
      }
    });
  }, []);

  const signInWithGoogle = async () => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      console.log("User logged in:", user);

      const userDocRef = doc(db, `users/${user.uid}`);
      const docSnap = await getDoc(userDocRef);

      if (!docSnap.exists()) {
        await setDoc(userDocRef, {
          name: user.displayName,
          email: user.email,
          userType: 'standard',
          creditBalance: 0,
          mbtiType: '',
          enneagramType: null,
        });
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleGetCredits = async () => {
    console.log('Starting handleGetCredits function');

    if (!currentUser || !currentUser.uid) {
      console.error('No currentUser or currentUser.uid found');
      return;
    }

    console.log(`Current user ID: ${currentUser.uid}`);

    try {
      console.log('Sending request to create Stripe Checkout session');
      const response = await axios.post('https://typewhisperer.com:3001/create-checkout-session', {
        priceId: 'price_1P247aIyXaZhhdwPIMOTSVg4', // Replace with your actual price ID
        userId: currentUser.uid,
      });
      console.log('Stripe Checkout session created successfully', response.data);

      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error redirecting to Stripe checkout:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  const analyzeDialogue = async () => {
    setLoading(true);

    if (isPremium && usePremiumService) {
      if (creditBalance > 0) {
        setCreditBalance(creditBalance - 1);

        const userDocRef = doc(db, `users/${auth.currentUser.uid}`);
        await setDoc(userDocRef, { creditBalance: creditBalance - 1 }, { merge: true });
      } else {
        console.log("Premium credits exhausted.");
        setLoading(false);
        return;
      }
    } else if (dialogueCount > 0) {
      setDialogueCount(dialogueCount - 1);

      const userDocRef = doc(db, `users/${auth.currentUser.uid}`);
      await setDoc(userDocRef, { dialogueCount: dialogueCount - 1 }, { merge: true });
    } else {
      console.log("Free tier limit reached or no premium credits left.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://typewhisperer.com:3001/analyze', { dialogue });
      setResult(response.data.analysis);

      if (auth.currentUser) {
        const userDialogueRef = collection(db, `users/${auth.currentUser.uid}/dialogues`);
        await addDoc(userDialogueRef, {
          dialogue,
          result: response.data.analysis,
          timestamp: serverTimestamp(),
        });
      }

    } catch (error) {
      console.error('Error sending analysis request:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogueChange = (e) => {
    if (e.target.value.length <= MAX_DIALOGUE_CHARACTERS) {
      setDialogue(e.target.value);
    }
  };

  const handleTogglePremium = async () => {
    if (isPremium) {
      setUsePremiumService(!usePremiumService);

      if (!usePremiumService) {
        // If turning OFF premium, update dialogueCount from Firestore
        const userDocRef = doc(db, `users/${currentUser.uid}`);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists() && docSnap.data().dialogueCount !== undefined) {
          setDialogueCount(docSnap.data().dialogueCount);
        } else {
          console.log("No dialogueCount found for the user.");
          // Handle the case where dialogueCount is not found (e.g., set a default value)
        }
      }
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const userDocRef = doc(db, `users/${currentUser.uid}`);
      await setDoc(userDocRef, { userType: 'standard' }, { merge: true });
      setIsPremium(false);
      // You might want to redirect the user or display a confirmation message
    } catch (error) {
      console.error('Error canceling subscription:', error);
      // Handle error (e.g., display an error message to the user)
    }
  };

  const [textToCopy, setTextToCopy] = useState(`
  AI-Optimized Personality Assessment Questionnaire

1. When faced with a problem, do you prefer to:
   a) Analyze it logically
   b) Consider how it affects people
   c) Trust your gut feeling

   Your answer:

   Elaboration (optional):

2. In social situations, do you tend to:
   a) Initiate conversations and meet new people
   b) Observe and listen more than speak
   c) Engage deeply with a few close friends

   Your answer:

   Elaboration (optional):

3. When making decisions, what's most important to you:
   a) Efficiency and practical outcomes
   b) Harmony and everyone's feelings
   c) Alignment with your personal values

   Your answer:

   Elaboration (optional):

4. How do you typically approach deadlines and schedules:
   a) Plan ahead and stick to the timeline
   b) Adapt as you go and work in bursts of energy
   c) Feel stressed but usually complete tasks just in time

   Your answer:

   Elaboration (optional):

5. Which statement resonates with you most:
   a) I strive for success and achievement
   b) I seek to understand and help others
   c) I aim for security and stability in life

   Your answer:

   Elaboration (optional):

6. In a group project, which role do you naturally take:
   a) The leader or organizer
   b) The mediator or team player
   c) The creative idea generator

   Your answer:

   Elaboration (optional):

7. How do you handle criticism:
   a) Analyze it objectively for improvement
   b) Take it personally and feel hurt
   c) Defend yourself or dismiss it

   Your answer:

   Elaboration (optional):

8. What energizes you most:
   a) Accomplishing tasks and being productive
   b) Connecting deeply with others
   c) Exploring new ideas and possibilities

   Your answer:

   Elaboration (optional):

9. Which fear is strongest for you:
   a) Being seen as incompetent or failing
   b) Being rejected or alone
   c) Being trapped or losing control

   Your answer:

   Elaboration (optional):

10. How do you prefer to spend your free time:
    a) Engaging in hobbies or learning new skills
    b) Socializing with friends and family
    c) Reflecting on personal thoughts and feelings

    Your answer:

    Elaboration (optional):

11. In a conflict, you're most likely to:
    a) Argue your point logically
    b) Try to find a compromise
    c) Avoid the conflict altogether

    Your answer:

    Elaboration (optional):

12. Which do you value most in a workplace:
    a) Clear structure and defined goals
    b) Collaborative and friendly atmosphere
    c) Autonomy and room for creativity

    Your answer:

    Elaboration (optional):

13. When stressed, you tend to:
    a) Become more controlling or perfectionistic
    b) Seek support from others
    c) Withdraw to process your emotions alone

    Your answer:

    Elaboration (optional):
  `);

  const handleCopyClick = () => {
    const element = document.createElement("textarea");
    element.value = textToCopy;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
    alert("MBTI and Enneagram Typing Template copied to clipboard!");
  };

  return (
    <div className="container">
      <header>
        <UserMenu
          loggedInUser={loggedInUser}
          logout={logout}
          signInWithGoogle={signInWithGoogle}
          mbtiType={mbtiType}
          setMbtiType={setMbtiType}
          creditBalance={creditBalance}
          userDetails={userDetails}
          isPremium={isPremium}
        />
      </header>

      <main className="futuristic-main">
  <div className="logo-container">
    <img
      src={`${process.env.PUBLIC_URL}/images/logo.webp`}
      alt="Logo"
      className="logo"
    />
  </div>

  <div className="image-container">
      <img src={`${process.env.PUBLIC_URL}/images/GCP.webp`} alt="Google Cloud Partner" className="gcp-logo" />
    </div>




  <section className="hero-section futuristic-box">
    <h1>Discover Your True Personality</h1>
    <h2>Powered by Advanced AI & Google Cloud</h2>
    <p className="hero-description">
      Share about yourself using our template or your own words. Our cutting-edge AI reveals your MBTI, Enneagram, Tritype, instinctual stack & now Communicaton style with confidence scores on the results.
       </p>
       <p>
      Start with 5 free analyses. Then unlock Premium insights:
      80 credits, in-depth profiling, Just $4.99/month
   </p>

    <button id="copy-button" type="button" onClick={handleCopyClick} className="futuristic-button">
      Copy Template
    </button>
  </section>
  {currentUser && (isPremium || dialogueCount > 0) ? (
    <section className="analysis-section">
      <div className="credits-info futuristic-box">
        {isPremium && usePremiumService ? (
          <h2>Remaining Credits: {creditBalance}</h2>
        ) : (
          <h2>Free tier: {dialogueCount} remaining</h2>
        )}
      </div>
      <div className="input-container futuristic-box">
        <label htmlFor="dialogue">Dialogue:</label>
        <textarea
          id="dialogue"
          value={dialogue}
          onChange={handleDialogueChange}
          maxLength={MAX_DIALOGUE_CHARACTERS}
        />
        <div className="character-count">{dialogue.length}/{MAX_DIALOGUE_CHARACTERS} characters</div>
        <button
          onClick={analyzeDialogue}
          className='futuristic-button'
          disabled={loading || (isPremium && usePremiumService && creditBalance <= 0) || (!isPremium && dialogueCount <= 0)}
        >
          Analyze
        </button>
        {isPremium && (
          <div className="switch-container">
            <span>Use Premium Features</span>
            <label className="switch">
              <input type="checkbox" checked={usePremiumService} onChange={handleTogglePremium} />
              <span className="slider round"></span>
            </label>
          </div>
        )}
        {loading && <div className="loading-bar"></div>}
      </div>
      <div className="result-container futuristic-box">
        <h2>Analysis:</h2>
        <textarea id="result" value={result} readOnly />
      </div>
    </section>
  ) : currentUser ? (
    <div className="upgrade-message futuristic-box">Free limit exceeded. Please upgrade to premium to continue using the service.</div>
  ) : (
    <div className="login-message futuristic-box">Please login to use Type Whisperer.</div>
  )}
  <footer className="futuristic-footer">
    <p>Made with ❤️ by MBTI Enthusiasts</p>
    <div className="credits-container">
      {!isPremium ? (
        <>
          <span>Credits: {creditBalance}</span>
          <button onClick={handleGetCredits} className="futuristic-button">Go Premium</button>
        </>
      ) : (
        <>
          <span>Subscription: Premium</span>
          <button onClick={handleCancelSubscription} className="futuristic-button">Cancel Subscription</button>
        </>
      )}
    </div>
  </footer>
</main>
    </div>
  );
}

const UserMenu = ({ loggedInUser, logout, signInWithGoogle, mbtiType, setMbtiType, creditBalance, userDetails, isPremium }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const updateMbtiType = async (e) => {
    setMbtiType(e.target.value);
    try {
      const userRef = doc(db, `users/${auth.currentUser.uid}`);
      await setDoc(userRef, { mbtiType: e.target.value }, { merge: true });
    } catch (error) {
      console.error("Error updating MBTI type: ", error);
    }
  };

  return (
    <div className="user-menu">
      {loggedInUser ? (
        <div>
          <button onClick={toggleDropdown}>
            {loggedInUser.displayName} ({mbtiType})
          </button>
          {dropdownVisible && (
            <div className="dropdown-menu">
              <button onClick={logout}>Logout</button>
              <button onClick={() => setShowProfilePopup(true)}>Profile</button>
            </div>
          )}
          {showProfilePopup && (
            <div className="profile-popup">
              <div className="profile-popup-window">
                <button className="close-profile-popup" onClick={() => setShowProfilePopup(false)}>
                  ×
                </button>
                <h2>Profile</h2>
                <p>Name: {loggedInUser}</p>
                <p>MBTI Type: {mbtiType}</p>
                <select id="mbtiType" value={mbtiType} onChange={updateMbtiType}>
                  <option value="">Select your MBTI type</option>
                  <option value="ENFJ">ENFJ</option>
                  <option value="ENFP">ENFP</option>
                  <option value="ENTJ">ENTJ</option>
                  <option value="ENTP">ENTP</option>
                  <option value="ESFJ">ESFJ</option>
                  <option value="ESFP">ESFP</option>
                  <option value="ESTJ">ESTJ</option>
                  <option value="ESTP">ESTP</option>
                  <option value="INFJ">INFJ</option>
                  <option value="INFP">INFP</option>
                  <option value="INTJ">INTJ</option>
                  <option value="INTP">INTP</option>
                  <option value="ISFJ">ISFJ</option>
                  <option value="ISFP">ISFP</option>
                  <option value="ISTJ">ISTJ</option>
                  <option value="ISTP">ISTP</option>
                </select>
                <br />
                {!isPremium && <p>Credits Balance: {creditBalance}</p>}
                {isPremium && <p>Subscription: Premium</p>}
              </div>
            </div>
          )}
        </div>
      ) : (
        <button onClick={signInWithGoogle}>Login with Google</button>
      )}
    </div>
  );
};

export default App;
